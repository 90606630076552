

const currentDate = new Date();

// Get the present date components
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // Months are 0-indexed, so add 1
const day = currentDate.getDate();
export const formattedDate = `${year}-${month}-${day}`;
const eventsOptions = [
  {
    category: "General Information",
    labelText: "Requestor Name",
    labelFor: "Requestor Name",
    id: "Requesters_Name",
    name: "Requester's Name",
    type: "text",
    autoComplete: "Requester Name",

    placeholder: "Requestor Name",
  },
  {
   
    labelText: "SPOC from Requestor's Team",
    labelFor: "SPOC from Requestor's Team",
    id: "SPOC_from_Requesters_Team",
    name: "SPOC from Requester's Team",
    type: "text",
    autoComplete: "SPOC from Requesters Team",

    placeholder: "SPOC from Requestor's Team",
  },
 

  {
    labelText: "Requestor Email",
    labelFor: "Requestor Email",
    id: "Requesters_Email",
    name: "Requester's Email *",
    type: "email",
    autoComplete: "Requestor Email",

    placeholder: "Requestor Email",
  },
  {
    labelText: "Requestor Phone Number",
    labelFor: "Requestor Phone Number",
    id: "Requesters_Phone_Number",
    name: "Requester's Phone Number",
    type: "text",
    autoComplete: "Requestor Phone Number",

    placeholder: "Requester Phone Number",
  },

  {
    labelText: "Team Name",
    labelFor: "Team Name",
    id: "Originating_Department",
    name: "Originating Department",
    type: "text",
    autoComplete: "Team Name",

    placeholder: "Team Name",
  },
  {
    labelText: "Type of Event",
    labelFor: "Type of Event",
    // id: "Type_of_Event",
    name: "Type_of_Event",
    options: [
      {
        name: "Internal",
        label: "Internal",
        value: "Internal",
        placeholder: "Please specify the department",

        // option:["Masters Relation" , "Career","Academics","Marketing","PGP","UG","PGP","PGP Rise","Masters Camps","Founders Office","HR","Finance"]
        option: [
          { label: "Masters' Union" },
          { label: "Career" },
          { label: "Academics" },
          { label: "Marketing" },
          { label: "CXO/ Guest Sessions" },
          { label: "Series C" },
          { label: "Masterclass/Workshop" },
          { label: "Founders Office" },
          { label: "HR" },
          { label: "Finance" },
          { label: "PGP" },
          { label: "UG" },
          { label: "PGP Rise" },
          { label: "MastersCamps" },
        ],
      },
      {
        id: "External",
        name: "External",
        value: "External",
        label: "External",

        option: [
          {
            labelText: "Organisation/Agency Name",
            labelFor: "Organisation_Name",
            id: "Organisation_Name",
            name: "Organisation_Name",
            type: "text",
            autoComplete: "Organisation_Name",

            placeholder: "Organisation Name",
          },
          {
            labelText: "Collaboration",
            labelFor: "Collaboration",
            id: "Collaboration",
            name: "Collaboration",
            type: "text",
            autoComplete: "Collaboration",

            placeholder: "Collaboration",
          },
          {
            labelText: "Venue",
            labelFor: "Venue",
            id: "Venue",
            name: "Venue",
            type: "text",
            autoComplete: "Venue",

            placeholder: "Venue",
          },
          {
            labelText: "Budget",
            labelFor: "Budget",
            id: "Event_Budget",
            name: "Budget",
            type: "text",
            autoComplete: "Budget",

            placeholder: "Budget",
          },
        ],
      },
    ],
  },
  {
    labelText: "Event Title",
    labelFor: "Event Title",
    id: "Event_Title",
    name: "Event Title *",
    type: "text",
    autoComplete: "Event Title",

    placeholder: "Event Title",
  },
  
  {
    labelText: "Event Description",
    labelFor: "Event Description",
    id: "Event_Description",
    name: "Event Description *",

    autoComplete: "Event Description",

    placeholder: "Event Description",
  },
  {
    id: "Event_Register_Date",
    labelText: "Event Register Date",
  },

  {
    labelText: "Proposed Event Date",
    subtext:
      "(The proposed event date must be provided at least 14 days before the event.)",
    labelFor: "Proposed Event Date",
    id: "Proposed_Event_Date",
    name: "Proposed Event Date *",
    type: "date",
    autoComplete: "Proposed Event Date",

    placeholder: "Proposed Event Date",
  },

  {
    labelText: "Event Day Type",
    labelFor: "Event Day Type",
    id: "Event_Day_Type",
    name: "EventDayType",
    options: [
      {
        name: "Weekday",

        value: "Weekday",
        label: "Weekday",
      },
      {
        name: "Weekend",

        value: "Weekend",
        label: "Weekend",
      },
    ],
    subtext: "(Value can only be entered at least 7 days before the event)",

    placeholder: "Event Day Type",
  },
  {
    labelText: "Proposed Event Start Time",
    labelFor: "Proposed Event Start Time",
    id: "Proposed_Event_Start_Time",
    name: "Proposed Event Start Time *",
    type: "time",
    autoComplete: "Proposed Event Start Time",
    isRequired: true,
    placeholder: "Proposed Event Start Time",
  },
  {
    labelText: "Proposed Event End Time",
    labelFor: "Proposed Event End Time",
    id: "Proposed_Event_End_Time",
    name: "Proposed Event End Time *",
    type: "time",
    autoComplete: "Proposed Event End Time",

    placeholder: "Proposed Event End Time",
  },
  {
    labelText: "Please specify the event location",
    labelFor: "Location",
    id: "Location",
    name: "Location",

    autoComplete: "Location",

    placeholder: "Location",
    options: [
      {
        name: "Auditorium (140 seats)",

        value: "Auditorium (140 seats)",
        label: "Auditorium (140 seats)",
      },
      {
        name: "Tower A Commons (250 seats)",

        value: "Tower A Commons (250 seats)",
        label: "Tower A Commons (250 seats)",
      },
      {
        name: "NPS",

        value: "NPS (70 seats)",
        label: "NPS (70 seats)",
      },
      {
        name: "EBIT",

        value: "EBIT (70 seats)",
        label: "EBIT (70 seats)",
      },
      {
        name: "Everett Rogers",

        value: "Everett Rogers (70 seats)",
        label: "Everett Rogers (70 seats)",
      },
      {
        name: "Black Scholes (70 seats)",

        value: "Black Scholes (70 seats)",
        label: "Black Scholes (70 seats)",
      },
      {
        name: "CAC (40 seats)",

        value: "CAC (40 seats)",
        label: "CAC (40 seats)",
      },
      {
        name: "GMV (15 seats)",

        value: "GMV (15 seats)",
        label: "GMV (15 seats)",
      },
      {
        name: "Tower C Commons (100 seats)",

        value: "Tower C Commons (100 seats)",
        label: "Tower C Commons (100 seats)",
      },
      {
        name: "ARR Room (8 seats)",

        value: "ARR Room (8 seats)",
        label: "ARR Room (8 seats)",
      },
      {
        name: "Boiler Room (8 seats)",

        value: "Boiler Room (8 seats)",
        label: "Boiler Room (8 seats)",
      },
      {
        name: "Others",
        id: "Other_Location",
        value: "Others",
        label: "Others",
      },
    ],
  },
  {
    labelText: "Please specify if you need Auditorium (Once confirmed, it can not be cancelled)",
    labelFor: "Auditorium",
    id: "Auditorium",
    name: "Auditorium",
    subtext:"(Requirements for the auditorium must be submitted at least 15 days before the event)"
  },
 
  {
   
    labelText: "Please provide the image link for the silver frame (if any).",
    labelFor: "silver frame",
    id: "silver_frame",
    name: "silver frame",
  },
  {
    labelText: "Is there any requirement for a student moderator?",
    labelFor: "Student moderator",
    id: "Student_moderator",
    name: "Student moderator",
    options: [
      {
        label: "Yes",
      },
      { label: "No" },
    ],
  },
  {
    labelText: "Attendees",
    labelFor: "Attendees",
    id: "Attendees",
    name: "Attendees",
    options: [
      {
        // id: "Student",
        name: "Student",

        value: "Student",
        label: "Student",

        option: [
          {
            value: 1,
            label: "UG",
            id: "UG",
            subtext:
              "(The expected number of UG student attendees has to be provided at least 5 days before the event)",
            option: [
              {
                value: 1,
                labelText: "UG",
                label: "UG",
                id: "UG",
                name: "UG",
                type: "number",
                autoComplete: "UG",

                placeholder: "No of UG student",
              },
            ],
          },
          {
            value: 2,
            label: "PGP",
            id: "PGP",
            placeholder: "Number of PGP students",
            subtext:
              "(The expected number of PGP student attendees has to be provided at least 5 days before the event)",
            option: [
              {
                labelText: "PGP",
                labelFor: "PGP",

                name: "PGP",
                type: "number",
                autoComplete: "PGP",
              },
            ],
          },
          {
            value: 3,
            label: "Rise",
            id: "RISE",
            subtext:
              "(The expected number of Rise student attendees has to be provided at least 5 days before the event)",
            option: [
              {
                labelText: "RISE",
                labelFor: "RISE",

                name: "RISE",
                type: "number",
                autoComplete: "RISE",

                placeholder: "Number of RISE students",
              },
            ],
          },
          {
            value: 4,
            label: "MasterCamps",
            id: "MasterCamps",
            subtext:
              "(The expected number of MasterCamps student attendees has to be provided at least 5 days before the event)",
            option: [
              {
                labelText: "MASTER_CAMPS",
                labelFor: "MASTER_CAMPS",
                id: "MASTER_CAMPS",
                name: "MASTER_CAMPS",
                type: "number",
                autoComplete: "MASTER CAMPS",

                placeholder:
                  "Expected Number of MasterCamps Student Attendees has to be prescribed at least 5 days before the event",
              },
            ],
          },
        ],
      },

      {
        // id: "Members",
        name: "Members",
        value: "Members",
        label: "Member",

        option: [
          {
            value: 1,
            labelText: "Internal_Guest",
            label: "Internal Members",
            id: "Internal_Guests",
            name: "Internal_Guest",
            type: "text",
            autoComplete: "Internal",
            subtext:
              "(The expected number of internal members has to be prescribed at least 5 days prior to the event date)",
            placeholder: "Internal",
          },
          {
            labelText: "External Guest",
            label: "External Guests",
            id: "External_Guests",
            name: "External_Guest",
            type: "text",
            autoComplete: "External Guest",
            subtext:
              "(The expected number of external guests has to be prescribed at least 3 days prior to the event date)",
            placeholder: "External Guest",
            option: [
              {
                labelText: "Number of External Guest",
                label: "Number of External Guest",
                id: "Number_of_External_Guest",
                placeholder: "Enter number of External Guests",
                type: "Number",
              },
              {
                labelText: "Guest Name",
                label: "Guest Name",
                id: "Guest_Name",
                type: "text",
                placeholder: "Enter the Guest Name",
              },
              {
                labelText: "Guest Linkedin",
                label: "Guest Linkedin",
                id: "Guest_Linkedin",
                type: "text",
                placeholder: "Enter Guest Linkedin",
              },
              {
                labelText: "Guest Arriving Date",
                label: "Guest Arriving Date",
                id: "Guest_Arriving_Date",
                type: "date",
                placeholder: "Enter the Guest Arrival Date",
              },
              {
                labelText: "Guest Arriving Time",
                label: "Guest Arriving Time",
                id: "Guest_Arriving_Time",
                type: "time",
                placeholder: "Enter the Guest Arrival Time",
              },
              {
                labelText: "Gift Requirement for Guest",
                label: "Gift Requirement for Guest",
                id: "Gift_Requirement_for_Guest",
                option: [
                  {
                    label:
                      "Tier 1 - Personalised Silver frame with printed photo (Cost to company 7 - 8k)",
                  },
                  { label: "Tier 2 - MU Goodies box (3000 -5000)" },
                  { label: "Tier 3 - MU Goodies box (1500 - 2500)" },
                  {
                    label:
                      "Tier 4 - Tea & coffee, Aroma candle/Aroma oil diffuser / Honey& dough box ( 500 - 1000)",
                  },
                  { label: "Others",
                id:"Other_Guest_Gift" },
                ],
              },
            ],
          },
        ],
      },
    ],

    placeholder: "External",
  },
  {
    labelText: "Sections open for",
    labelFor: "Sections",
    id: "Sections_open_for",
    name: "Sections",
    options:[{
      label:"Section A"
    },{
      label:"Section B"
    },{
      label:"Section C"
    },{
      label:"Section D"
    },{label:"Selective Student"}]
  },



  {
    labelText: "Schedule",
    labelFor: "Schedule",
    id: "Schedule",
    name: "Schedule",
    type: "text",
    autoComplete: "Schedule",
    subtext: "(The schedule has to be shared at least 8 days before the event)",

    placeholder: "Schedule",
  },

  {
    labelText: "Total Number of Attendees",
    label: "Total Number of Attendees",
    id: "Total_Number_of_Attendees",
    name: "Total Number of Attendees",
    type: "number",
    autoComplete: "Total Number of Attendees",

    placeholder: "Total Number of Attendees",
    subtext:
      "(The expected total number of attendees has to be prescribed at least 2 days before the event)",
    placeholder: "Expected Number of Attendees",
  },
  {
    labelText: "Logistics",
    label: "Logistics",

    name: "Logistics",
    category:"Logistics Information",
    autoComplete: "Logistics",
    options: [
      {
        labelText: "Parking_Arrangements",
        label: "Parking_Arrangements",
        id: "Parking_Arrangements",
        name: "Parking Arrangements",

        autoComplete: "Parking Arrangements",
        subtext:
          "(Parking Requirements have to be provided at least 4 days before the event. For visitors, we have 12 parking spaces available)",
      },
      {
        labelText: "Transportation_Services",
        label: "Transportation_Services",
        id: "Transportation_Services",
        name: "Transportation Services",

        autoComplete: "Transportation Services",
        subtext:
          "(Transportation Requirements have to be shared at least 5 days before the event)",
      },
      {
        labelText: "Security_Staff",
        label: "Security_Staff",
        id: "Security_Staff",
        name: "Security Staff",

        autoComplete: "Security Staff",
        subtext:
          "(Security Staff requirements have to be shared at least 5 days before the event)",
      },
      {
        labelText: "Other_Logistics",
        label: "Other_Logistics",
        id: "Other_Logistics",
        name: "Other Logistics",
        type: "Other Logistics",
        autoComplete: "Other Logistics",
        subtext:
          "(Other Logistical requirements have to be shared at least 7 days before the event.)",
          
      },
    ],
  },

  {
    labelText: "Equipment/ Technology",
    label: "Equipment/ Technology",
   
    name: "Equipment_Technology",

    autoComplete: "Equipment/ Technology",

    placeholder: "Equipment/ Technology",

    options: [
      {
        value: 1,
        labelText: "Equipment",
        label: "Equipment",
        id: "Equipment",
        name: "Equipment",

        autoComplete: "Equipment",

        placeholder: "Equipment",
        subtext:
          "(Equipment requirements have to be shared at least 4 days before the event)",
        autoComplete: "Equipment",
        option: [
          {
            value: 1,
            labelText: "Equipment Description",
            label: "Equipment",

            name: "Equipment_Description",

            type: "textarea",

            placeholder: "Equipment_Description",
          },
        ],
      },

      {
        value: 1,
        labelText: "Mic",
        label: "Mic",
        id: "Mic",
        name: "Mic",
        type: "textarea",
        autoComplete: "Mic",

        placeholder: "Mic",
     
        placeholder: "IT Support",
      },
      {
        value: 1,
        labelText: "IT_Setup",
        label: "IT_Setup",
        id: "IT_Setup.",
        name: "IT_Setup",
        type: "textarea",
        autoComplete: "IT_Setup",

        placeholder: "IT_Setup",
     
        placeholder: "IT_Setup",
      },
      {
        value: 1,
        labelText: "Tech_Support",
        label: "Tech_Support",
        id: "Tech_Support",
        name: "Tech Support",
        type: "textarea",
        autoComplete: "Equipment",

        placeholder: "Equipment",
        subtext:
          "(Tech support requirements have to be shared at least 3 days before the event)",
        placeholder: "IT Support",
      },
    ],
  },
  {
    labelText: "Seating",
    label: "Seating",
    // id: "Seating",
    name: "Seating",
    type: "dropdown",
    autoComplete: "Seating",

    placeholder: "Seating",
    options: [
      {
        labelText: "Customized Seating Arrangement",
        label: "Customized Seating Arrangement",
        id: "Seating_Arrangement",
        name: "Seating Arrangement",
        type: "dropdown",
        autoComplete: "Seating Arrangement",
        subtext:
          "(Seating requirements have to be provided at least 2 days before the event)",
      },
      {
        labelText: "VIP Seating",
        label: "VIP_Seating",
        id: "VIP_Seating",
        name: "Seating",
        type: "dropdown",
        autoComplete: "Seating",
        subtext:
          "(VIP Seating requirements have to be provided at least 2 days before the event)",
      },

      {
        labelText: "Outsourced_Seating",
        label: "Outsourced_Seating",
        id: "Outsourced_Seating",
        name: "outsources",
        type: "outsources",
        autoComplete: "outsources",
        subtext:
          "(Outsourced Seating requirements have to be provided at least 10 days before the event)",
      },
    ],
  },

  {
    labelText: "Special Requirement",
    label: "Special Requirement",
    id: "Special_Requirement",

    options: [
      {
        labelText: "Special_Accommodation",
        label: "Special_Accommodation",

        name: "Special Accommodation",
        id: "Special_Accommodation",
        autoComplete: "outsources",
        subtext:
          "(Special Accommodation requirements have to be shared at least 5 days before the event)",
        autoComplete: "Special Accommodation",
        option: [
          {
            labelText: "Special Accommodation",
            label: "Special Accommodation",
            id: "Special_Accommodation",
            name: "Special Accommodation",
            type: "text",
            autoComplete: "Special Accommodation",
            placeholder: "Enter the description",
          },
        ],
      },
      {
        labelText: "Special Guests_or_Performers",
        label: "Special_Guests_or_Performers",
        id: "Special_Guests_or_Performers",
        name: "Special Guests or Performers",
        type: "text",
        autoComplete: "Special Guests or Performers",
        subtext:
          "(Specific Requirements for special guests have to be provided 8 days before the event)",
        placeholder: "Special guests and performer",
        option: [
          {
            labelText: "Special Guests_or_Performers",
            label: "Special Guests_or_Performers",
            id: "Special Guests_or_Performers",
            name: "Special Guests_or_Performers",
            type: "text",
            autoComplete: "Special Guests or Performers",
          },
        ],
      },
      {
        labelText: "Medical_Support",
        label: "Medical_Support",
        id: "Medical_Support",
        name: "Medical Support",

        autoComplete: "Medical Support",
        subtext:
          "(Medical support for guests has to be informed at least 7 days before the event)",
      },
      {
        labelText: "Stationary_Requirements",
        label: "Stationary_Requirements",
        id: "Stationary_Requirements",
        name: "Stationary Requirements",

        autoComplete: "Stationary Requirements",
        subtext:
          "(Stationery requirements have to be provided at least 3 days prior to the event)",
      },
      {
        labelText: "Decoration_and_Theme",
        label: "Decoration_and_Theme",
        id: "Decoration_and_Theme",
        name: "Decoration and Theme",

        autoComplete: "Decoration and Theme",
        subtext:
          "(Decoration and Theme requirements have to be shared at least 8 days before the event)",
      },

      {
        labelText: "Photography_and_Videography",
        label: "Photography and Videography",
        id: "PhotographyandVideography",
        name: "PhotographyandVideography",

        autoComplete: "Photography and Videography",
        subtext:
          "(Photography/ Videography requirements have to be shared at least 7 days before the event)",
        option: [
          {
            labelText: "Expert Photography/ Videography",
            label: "Expert_Photography/Videography",

            name: "Expert Photography/ Videography",

            autoComplete: "Expert Photography/ Videography",
            placeholder: "Expert Photography/ Videography",
          },
          {
            labelText: "Any Limitations",
            label: "Any Limitations",

            name: "Any Limitations",
            type: "text",
            autoComplete: "Any Limitations",
            placeholder: "Any Limitations",
          },
        ],
      },
    ],
  },



  {
    labelText: "Food Category",
    label: "Food Category",

    name: "Food_Category",

    autoComplete: "Food Category",
    options: [
      {
        labelText: "In-house Catering",
        label: "In-house Catering",

        name: "In_house_Catering",

        autoComplete: "In-house Catering",

        option: [
          {
            labelText: "DLF_Food_Court",
            label: "DLF_Food_Court",
            id: "DLF_Food_Court",
            name: "DLF Food Court",

            autoComplete: "DLF Food Court",
            subtext:
              "(DLF Food Court availability requirements have to be shared at least 5 days before the event)",
          },
          {
            labelText: "In-house_Caterers",
            label: "In-house_Caterers",
            id: "In_house_Caterers",
            name: "In-house Caterers",

            autoComplete: "In-house Caterers",
            subtext:
              "(Requirement for In-house Caterers has to be shared at least 5 days before the event)",
          },
        ],
      },
      {
        labelText: "Vendor",
        label: "Vendor",
        id: "Vendor",
        name: "Vendor",

        autoComplete: "Vendor",
        subtext:
          "(Vendor requirements have to be provided at least 15 days before the event)",
      },
    ],
  },
  
  {
    labelText: "Food Preferences & Cutlery",
    label: "Food Preferences & Cutlery",
    id: "Food_Preferences_&_Cutlery",
    name: "Food Preferences & Cutlery",

    autoComplete: "Food Preferences & Cutlery",
    options: [
      {
        labelText: "Cutlery",
        label: "Cutlery",
        id: "Cutlery",
        name: "Cutlery",
        placeholder: "Cutlery",
        autoComplete: "Cutlery",
        subtext:
          "(Cutlery requirements have to be shared at least 15 days before the event)",
      },
      {
        labelText: "Specific_Food_Menu",
        label: "Specific_Food_Menu",
        id: "Specific_Food_Menu",
        name: "Specific_Food_Menu",
        placeholder: "Specific Food Menu",
        autoComplete: "Specific Food Menu",
        subtext:
          "(Specific Food Menu requirements have to be shared at least 10 days before the event)",
      },
      {
        labelText: "Dietary_Requirement",
        label: "Dietary_Requirement",
        id: " Dietary_Requirement",
        name: "Dietary Requirement",
        placeholder: "Dietary Requirement",
        autoComplete: "Dietary Requirement",
        subtext:
          "(Any dietary requirements have to be shared at least 5 days before the event)",
      },
    ],
  },
  {
    
      labelText: "Please select all meals that you would need to be arranged for your guest",
      label: "Meals required",
      id: "Meals_required",
      name: "Meals required",
      options:[{label:"Breakfast (Light)"},{label:"Breakfast (Full spread)"},{label:"Lunch (light)"},{label:"Lunch ( Full spread)"},{label:"Dinner ( Light)"},{label:"Dinner ( Full spread)"},{label:"Only Tea/coffee with cookies"}]
    
  },
  {
    labelText: "Please confirm the catering quality that you would need. This should be in line with your guest tier!",
    label: "Catering Quality",
    id: "Catering_Quality",
    name: "Catering Quality",
    options:[{label:"Tier 1 (Oberoi/ Ramada/ Radisson level Catering for CXO of big companies)"},{label:"Tier 2 Vendor (External vendor, please specify)"},{label:"Tier 3 Food Court: (Honey & Dough level catering/ Starbucks)"},{label:"Tier 4 (Basic catering- Inhouse)"}]
  },
  {
    labelText: "Standees",
    label: "Standees",

    name: "Standees",

    autoComplete: "Standees",

    options: [
      {
        labelText: "Yes",
        label: "Yes",

        name: "Yes",

        option: [
          {
            labelText: "Goal",
            label: "Goal",
            id: "Goal",
            name: "Goal",
            type: "text",
            autoComplete: "Goal",
            subtext:
              "(Standee display goals have to be provided at least 7 days before the event)",
          },
          {
            labelText: "Standees Start Date",
            label: "Standees Start Date",
            id: "Standees_Start_Date",
            name: "Standees Start Date",
            type: "date",
            autoComplete: "Standees Start Date",
          },
          {
            labelText: "Standees End Date",
            label: "Standees End Date",
            id: "Standees_End_Date",
            name: "Standees End Date",
            type: "date",
            autoComplete: "Standees End Date",
          },
          {
            labelText: "Locations",
            label: "Locations",
            type: "text",
            name: "Locations",
            id: "Standees_Locations",
            autoComplete: "Locations",
            subtext:
              "(Proposed locations for standees have to be shared at least 7 days before the event)",
          },
          {
            labelText: "Design",
            label: "Design",
            type: "Description",
            name: "Design",
            id: "Design",
            autoComplete: "Design",
            subtext:
              "(Design and Content to be displayed on standees have to be provided at least 7 days before the event)",
          },
          {
            labelText: "Number and Size of Standees",
            label: "Number and Size of Standees",
            type: "Description",
            name: "Number and Size of Standees",
            id: "Number_and_Size_of_Standees",
            autoComplete: "Number and Size of Standees",
            subtext:
              "(Number and Size of standees have to be provided at least 7 days before the event)",
          },

          {
            labelText: "Any other requirements",
            label: "Any other requirements",
            type: "Description",
            name: "Any other requirements",
            id: "Any_other_requirements",
            autoComplete: "Any other requirements",
          },
        ],
      },
    ],
  },
  {
    labelText: "Tier",
    label: "Tier",

    name: "Tier",
    placeholder: "Tier",
    autoComplete: "Tier",
    options: [
      {
        labelText: "Tier I",
        label: "Tier I",

        name: "Tier I",
        placeholder: "Tier I",
        autoComplete: "Tier I",
      },
      {
        labelText: "Tier II",
        label: "Tier II",

        name: "Tier II",
        placeholder: "Tier II",
        autoComplete: "Tier II",
      },
      {
        labelText: "Tier III",
        label: "Tier III",

        name: "Tier III",
        placeholder: "Tier III",
        autoComplete: "Tier III",
      },
      {
        labelText: "Tier IV",
        label: "Tier IV",

        name: "Tier IV",
        placeholder: "Tier IV",
        autoComplete: "Tier IV",
      },
      {
        labelText: "Tier V",
        label: "Tier V",

        name: "Tier V",
        placeholder: "Tier V",
        autoComplete: "Tier V",
      },
      {
        labelText: "Tier VI",
        label: "Tier VI",

        name: "Tier VI",
        placeholder: "Tier VI",
        autoComplete: "Tier VI",
      },
    ],
  },
  {
    labelText: "Budget",
    label: "Budget",

    name: "Budget",
    placeholder: "Budget",
    autoComplete: "Budget",
    options: [
      {
        labelText: "1000",
        label: "1000",

        name: "1000",
        placeholder: "1000",
      },
      {
        labelText: "2000",
        label: "2000",

        name: "2000",
        placeholder: "2000",
      },
      {
        labelText: "4000",
        label: "4000",

        name: "4000",
        placeholder: "4000",
      },
      {
        labelText: "6000",
        label: "6000",

        name: "6000",
        placeholder: "6000",
      },
      {
        labelText: "10000",
        label: "10000",

        name: "10000",
        placeholder: "10000",
      },
      {
        labelText: "More Than 10000",
        label: "More Than 10000",

        name: "More Than 10000",
        placeholder: "More Than 10000",
      },
    ],
  },
  {
    labelText: "Approval Required",
    label: "Approval_Required",
    // id: "Approval_Required",
    name: "Approval_Required",
    placeholder: "Approval Required",
    autoComplete: "Approval Required",
    options: [
      {
        labelText: "Pratham",
        label: "Pratham",

        name: "Pratham",
        placeholder: "Pratham",
      },
      {
        labelText: "Department Head",
        label: "Department Head",

        name: "Department Head",
        placeholder: "Department Head",
      },
      {
        labelText: "Reporting manager",
        label: "Reporting manager",

        name: "Reporting manager",
        placeholder: "Reporting manager",
      },
    ],
  },
  {
    labelText: "Inclusions",
    label: "Inclusions",
    id:"Inclusions",
    name: "Inclusions",
    placeholder: "Inclusions",
    autoComplete: "Inclusions",
    options: [
      {
        labelText:
          "MU Luxury Goodie Box + Silver Frame/ Camera Or similar to some range depending on Customization and time",
        label:
          "MU Luxury Goodie Box + Silver Frame/ Camera Or similar to some range depending on Customization and time",

        name: "MU Luxury Goodie Box + Silver Frame/ Camera Or similar to some range depending on Customization and time",
      },
      {
        labelText:
          "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen + Echo Dot Or Silver Frame",
        label:
          "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen + Echo Dot Or Silver Frame",

        name: "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen + Echo Dot Or Silver Frame",
      },
      {
        labelText:
          "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen Or Camera",
        label:
          "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen Or Camera",

        name: "MU Goodie Box - French Press + Coffee + Berry + MU Sipper + Tshirt + Diary + Plantable Kit + Pen Or Camera",
      },
      {
        labelText:
          " MU Goodie box - Sipper + Plantable kit + Candle + Coffee + Berries Jar",
        label:
          " MU Goodie box - Sipper + Plantable kit + Candle + Coffee + Berries Jar",

        name: " MU Goodie box - Sipper + Plantable kit + Candle + Coffee + Berries Jar",
      },
      {
        labelText: "MU Goodie Bag - White Bottle, Mug, Pen, Notebook, Mask",
        label: "MU Goodie Bag - White Bottle, Mug, Pen, Notebook, Mask",

        name: "MU Goodie Bag - White Bottle, Mug, Pen, Notebook, Mask",
      },
      {
        labelText: "MU Jute bag - MU Bottle + Diary + Pen + Spiral dairy",
        label: "MU Goodie Bag - White Bottle, Mug, Pen, Notebook, Mask",

        name: "MU Goodie Bag - White Bottle, Mug, Pen, Notebook, Mask",
      },
    ],
  },
  {
    labelText: "Special Gifting",
    label: "Special Gifting",
    // id: "Special_Gifting",
    name: "Special Gifting",
    placeholder: "Gifting To",
    autoComplete: "Gifting To",
    options: [
      {
        labelText: "Customized_Gifting",
        label: "Customized_Gifting",

        name: "Customized_Gifting",
        placeholder: "Customized Gifting",
        subtext:
          "(Specially customized gifting requirements have to be shared at least 15 days before the event)",
      },
      {
        labelText: "New_Goodies",
        label: "New_Goodies",

        name: "New_Goodies",
        placeholder: "New Goodies",
        subtext:
          "(New goodies for gifting requirements have to be shared at least 30 days before the event)",
      },
    ],
  },
  {
    labelText: "If you have any marketing requirements, please mention the same.",
    label: "Marketing requirements",
     id:"Marketing_requirements",
    name: "New_Goodies",
    placeholder: "New Goodies",
    category:"Marketing Information"
    
  },
  {
    labelText: "Is there any additional information or specific requests you would like to share regarding the event?",
    label: "Additional information or specific requests for marketing",
     id:"Additional_information_or_specific_requests_for_marketing",
    name: "Additional information or specific requests for marketing",
    placeholder: "Enter the Additional information or specific requests for marketing ",
   
  }
];
export { eventsOptions };
