import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Loader from "./Loader";

const Table = ({ handleChangeroute }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Add state for current page
  const itemsPerPage = 10; // Set the number of events per page
  const [DeleteEventsuccess, setDeleteEventSuccess] = useState();
  const [DeleteEventerror, setDeleteEventerror] = useState();
  const [cookies] = useCookies();
  const [query, setQuery] = useState("");
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("https://api-v2.mastersunion.in/api/v1/getevent")
      .then((response) => {
        setData(response.data.user);
      })
      .catch((error) => {
        setData(error.data);
      });
  }, []);

  const handleDelete = (Id) => {
    axios
      .delete(`https://api-v2.mastersunion.in/api/v1/event/delete?id=${Id}`)
      .then((response) => {
        setDeleteEventSuccess(response.data);
      })
      .catch((error) => {
        setDeleteEventerror(error.data);
      });
  };

  useEffect(() => {
    if (DeleteEventsuccess) {
      alert.success("Event Deleted Successfully");
      window.location.reload();
    }
    if (DeleteEventerror) {
      alert.error("Failed to delete event.");
    }
  }, [DeleteEventsuccess, DeleteEventerror, alert]);

  if (!cookies?.loginData) {
    navigate("/login");
  }

  const initialUserArray = data || [];
  const [sortedUser, setSortedUser] = useState([...initialUserArray]);

  const sortByEventDateDescending = () => {
    try {
      const sorted = [...initialUserArray].sort((a, b) => {
        return new Date(b.Proposed_Event_Date) - new Date(a.Proposed_Event_Date);
      });
      setSortedUser(sorted);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value.toLowerCase());
    filterArrayBySearchQuery(sortedUser, query);
  };

  function filterArrayBySearchQuery(sortedUser, searchQuery) {
    if (!searchQuery) {
      return sortedUser;
    }

    const query = searchQuery.toLowerCase();
    return sortedUser.filter((item) => {
      const searchableProperty1 = item.Event_Title?.toLowerCase();
      const searchableProperty2 = item.Event_Description?.toLowerCase();
      const searchableProperty3 = item?.Originating_Department?.toLowerCase();
      const searchableProperty4 = item?.Location.map((item) => item?.value);

      return (
        searchableProperty1?.includes(query) ||
        searchableProperty2?.includes(query) ||
        searchableProperty3?.includes(query) ||
        searchableProperty4?.includes(query)
      );
    });
  }

  useEffect(() => {
    sortByEventDateDescending();
  }, [data]);

  // Pagination Logic
  const totalPages = Math.ceil(sortedUser.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = filterArrayBySearchQuery(sortedUser, query).slice(
    startIndex,
    endIndex
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handlenavigate = (id) => {
    handleChangeroute(id);
    navigate("/event-manage/eventFullview");
  };
  

  return (


   <>
   { !initialUserArray.length==0 ?  <div className='className="overflow-x-auto "'>
      <div className="flex flex-inline gap-8 pb-3 pt-3 pl-4">
        <button
          onClick={() => {
            navigate("/event-manage/event-form");
          }}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          + Create Event
        </button>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            className="border rounded-md p-2"
            style={{ width: "350px" }}
            placeholder="Search Events by event title, event description"
            value={query}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <table className="min-w-full bg-white border border-gray-300 p-4 ">
        <thead>
          <tr>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Event Title
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Feedback
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider w-36">
              Event Date
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider w-44">
              Event Start Time
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Event Location
            </th>
            <th className="py-3 px-6 border-b border-gray-300 bg-gray-100 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems?.map((item) => {
            const isExpired = new Date(item.Proposed_Event_Date) < new Date();
            return (
              <tr key={item._id} className="even:bg-gray-100">
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Event_Title}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {!item.Feedback ||
                  item.Feedback.question9answer === "" ||
                  item.Feedback.question9answer === "Not Answered" ? (
                    <a
                      href={`https://mastersunion481.outgrow.us/Copy-of-Copy-of-mastersunion481-518-2-1?gclid=${item._id}&question1answer=${item.Event_Title}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className={`bg-blue-500 text-white p-1 rounded-md ${
                          !isExpired
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                        disabled={!isExpired}
                      >
                        Share Feedback
                      </button>
                    </a>
                  ) : (
                    item.Feedback?.question9answer
                  )}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Proposed_Event_Date}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Proposed_Event_Start_Time}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  {item.Location[0]?.value}
                </td>
                <td className="py-4 px-6 border-b border-gray-300 text-sm">
                  <div className="flex flex-inline w-48 gap-2">
                    <button
                      onClick={() => {
                        handleDelete(item._id);
                      }}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    >
                      delete
                    </button>
                    <button
                      onClick={() => {
                        handlenavigate(item._id);
                      }}
                      className="animate-pulse even:bg-gray-200 inline-block p-2 rounded hover:bg-gray-300"
                    >
                      view full
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>


{/* Pagination Controls */}

<div className="flex justify-center mt-4">
  <div className="w-64 overflow-x-auto"> {/* For horizontal scrolling */}
    <nav aria-label="Page navigation">
      <ul className="inline-flex space-x-2">
        {currentPage > 1 && (
          <li>
            <button
              onClick={() => handlePageChange(1)}
              className={`px-3 py-2 border rounded-lg ${
                currentPage === 1
                  ? "bg-blue-500 text-white"
                  : "bg-white text-blue-500 border-gray-300"
              } transition duration-200 ease-in-out`}
            >
              1
            </button>
          </li>
        )}
        {currentPage > 3 && <li className="flex items-center">...</li>}
        
        {Array.from({ length: totalPages }, (_, index) => {
          const pageNumber = index + 1;

          // Only show a limited number of page numbers for better UX
          if (pageNumber < 3 || 
              pageNumber > totalPages - 2 || 
              (pageNumber >= currentPage - 1 && pageNumber <= currentPage + 1)) {
            return (
              <li key={index}>
                <button
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-3 py-2 border rounded-lg ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-white text-blue-500 border-gray-300"
                  } transition duration-200 ease-in-out`}
                >
                  {pageNumber}
                </button>
              </li>
            );
          }

          return null; // Skip rendering for other pages
        })}

        {currentPage < totalPages - 2 && <li className="flex items-center">...</li>}
        {currentPage < totalPages && (
          <li>
            <button
              onClick={() => handlePageChange(totalPages)}
              className={`px-3 py-2 border rounded-lg ${
                currentPage === totalPages
                  ? "bg-blue-500 text-white"
                  : "bg-white text-blue-500 border-gray-300"
              } transition duration-200 ease-in-out`}
            >
              {totalPages}
            </button>
          </li>
        )}
      </ul>
    </nav>
  </div>
</div>






    </div> : ( <div className="flex justify-center items-center  " style={{marginTop:"300px"}}>
        <Loader/>
        </div>)}
   </>
   
  );
};

export default Table;
