import Select from "react-select";

import { useState } from "react";
import { checklabel, removeSpacesAndJoin } from "./formFunctions";
import { trimAndJoinString } from "./Showevent";
import { formattedDate } from "./Formfield";
export default function Plusinput({
  value,
  key,
  EventChange,
  handledropdowndata,
  othervalue,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired,
  Placeholder,
  options,
  formErrors,
  subtext,
  formErrorsobj,
  category,
}) {
  // selectedValue={selectedValue}
  const [selectedDrinks, setSelectedDrinks] = useState([]);
  const [internalName, setInternalName] = useState([]);
  const [specificReq, setSpecificReq] = useState([]);

  const [seating, setSeating] = useState([]);
  const [SpecialAccommodation, setSpecialAccommodation] = useState([]);
  const [photoVideo, setPhotoVideo] = useState([]);
  const [standeesBol, setStandeesbol] = useState([]);
  const [student, setStudent] = useState([]);
  const [food, setFood] = useState([]);
  const [logistics, setLogistics] = useState([]);
  const [FoodPreferences, setFoodPreferencesCutlery] = useState([]);
  const arraySize = 0; // Set the desired size of the array
  const defaultValue = 0;
  const [Tier, setTier] = useState("");
  const [location, setLocation] = useState([]);
  const [inhouse, setInhouse] = useState([]);
  const [gifting, setGifting] = useState([]);
  const [guestGift, setGuestGift] = useState("");
  const [eventDayType, setEventDayType] = useState("");
  const [StudentModerator, setStudentModerator] = useState("");
  const [section, setSection] = useState([]);
  const [foodQuality, , setFoodQuality] = useState([]);
  const [meals, setMeals] = useState([]);
  const handleDrinksChange = (selectedOptions) => {
    setSelectedDrinks(selectedOptions);
    handledropdowndata(name, selectedOptions);
    // const selectedDrinkLabels = selectedOptions.map((option) => option.label);
    // setDisplayText(
    //   selectedDrinkLabels.includes("Beer") || selectedDrinkLabels.includes("Rum")
    //     ? "Enjoy your drinks!"
    //     : ""
    // );
  };

  const Inhouse = (selectedOption) => {
    setInhouse(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const FoodPreferencesCutlery = (selectedOption) => {
    setFoodPreferencesCutlery(selectedOption);
  };
  const TierFunc = (selectedOption) => {
    setTier(selectedOption.value);

    handledropdowndata(selectedOption.name, selectedOption.value);
  };
  const handleStandees = (selectedOption) => {
    if (standeesBol.length <= 1) {
      setStandeesbol(selectedOption);
    }

    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };

  const handleLogistics = (selectedOption) => {
    setLogistics(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const handleFood = (selectedOption) => {
    setFood(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const handleStudentCourceNum = (selectedOption) => {
    setStudent(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };

  const handleInternal = (selectedoption) => {
    handledropdowndata(selectedoption[0]?.name, selectedoption);
  };
  const handleMemberNum = (selectedOption) => {
    setInternalName(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };

  const SpecificRequirement = (selectedOption) => {
    setSpecificReq(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const handleSeating = (selectedOption) => {
    setSeating(selectedOption);
    handledropdowndata(id, selectedOption);
  };

  const handleSpecialAccommodation = (selectedOption) => {
    setSpecialAccommodation(selectedOption);

    handledropdowndata(id, selectedOption);
  };
  const handlePhotoVideo = (selectedOption) => {
    setPhotoVideo(selectedOption);
    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const handleGifting = (selectedOption) => {
    setGifting(selectedOption);

    handledropdowndata(selectedOption[0]?.name, selectedOption);
  };
  const handleLocation = (selectedOption) => {
console.log(selectedOption,"fffffffffffffffffffff");
    setLocation(selectedOption);
    handledropdowndata(name, selectedOption);
  };
  const handleDayType = (selectedOption) => {
    setEventDayType(selectedOption.value);
    handledropdowndata(selectedOption?.id, selectedOption?.value);
  };
  const handleGuestGift = (selectedOption) => {
    setGuestGift(selectedOption);
    handledropdowndata(selectedOption?.id, selectedOption?.value);
  };
  const handleStudentModerator = (selectedOption) => {
    setStudentModerator(selectedOption);
  };
  const handleSection = (selectedOption) => {
  
    setSection(selectedOption);
    handledropdowndata(selectedOption[0]?.id, selectedOption);
  };
  const handlefoodQuality = (selectedOption) => {
    // setFoodQuality(selectedOption?.label);

    handledropdowndata(selectedOption?.id, selectedOption?.value);
  };
  const handlemeals = (selectedOption) => {
   
    setMeals(selectedOption);
    handledropdowndata(selectedOption[0]?.id, selectedOption);
  };


  return (
    <>
      <div className="">
        <div className="pb-2">
          {(labelText == "Team Name" ||
            labelText === "Requestor Name" ||
            labelText === "Requestor Email" ||
            labelText === "Requestor Phone Number" ||
            labelText === "SPOC from Requestor's Team" ||
            labelText === "Event Title" ||
            labelText === "Proposed Event Date" ||
            labelText === "Proposed Event Start Time" ||
            labelText === "Proposed Event End Time") && (
            <>
              <label className="block text-sm font-semibold text-gray-500 pt-2 pb-2">
                {name}
              </label>
              <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                {" "}
                {subtext}
              </p>
              <input
                onChange={EventChange}
                // value={othervalue}
                id={id}
                name={name}
                type={type}
                required={isRequired}
                className={"w-full h-9 focus:outline-none"}
                placeholder={Placeholder}
              />
              {formErrors && (
                <div className="error">
                  <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                    {formErrors}
                  </p>
                </div>
              )}
            </>
          )}
          {labelText === "Event Description" || labelText === "Schedule" ? (
            <>
              {" "}
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500 ">
                  {name}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {subtext}
                </p>

                <textarea
                  id={id}
                  onChange={EventChange}
                  placeholder={Placeholder}
                  className="w-full focus:outline-none"
                />
                {formErrorsobj && (
                  <div className="error">
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[id]}
                    </p>
                  </div>
                )}
              </div>{" "}
            </>
          ) : (
            ""
          )}
          {labelText == "Event Day Type" && (
            <>
              <label className="block text-sm font-semibold text-gray-500">
                {labelText}
              </label>

              <Select
                name={name}
                id={id}
                isMulti={false}
                options={options?.map((food) => ({
                  value: food.label,
                  label: food.label,
                  name: name,
                  id: id,
                }))}
                onChange={(name) => {
                  handleDayType(name);
                }}
              />
              
            </>
          )}
           {/* {console.log(id,"ggggggggggggggggggggggggggggg" ,)} */}
          {labelText === "Please specify the event location" ? (
            <>
              <label className="block text-sm font-semibold text-gray-500">
                {labelText+"*"}
              </label>
             
              <Select
                name="typeOfEvent"
                isMulti
                options={options?.map((food) => ({
                  
                  value: food.label,
                  label: food.label,
                  name: food.label,
                } ))}
                onChange={(name) => {
                  handleLocation(name);
                }}
              />
            </>
          ) : (
            ""
          )}
          {/* {labelText==="Event Register Date" ? <> <label className="block text-sm font-semibold text-gray-500 ">
                  {name}
                </label> <p>{value}  </p> </> :""} */}
         
          {location?.map((item) => {
            return (
              <>
           
                {item.label === "Others" ? (
                  <>
                    <div className="pt-2">
                      <label className="block text-sm font-semibold text-gray-500 ">
                        {location.name}
                      </label>
                      <textarea
                        id={"Other_Location"}
                        onChange={EventChange}
                        placeholder="If others please specify."
                        className="w-full focus:outline-none"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {checklabel(labelText) ? (
            <>
              {" "}
              <label className="block text-sm font-semibold text-gray-500">
                {labelText}
              </label>
             
              <Select
                name="typeOfEvent"
                isMulti
                options={options?.map((food) => ({
                  value: food.label,
                  label: food.label,
                  id: food.id,
                }))}
                onChange={(name) => {
                  handleDrinksChange(name);
                }}
              />
            </>
          ) : (
            ""
          )}
          {selectedDrinks?.map((item) => {
            return (
              <>
                {item.label === "Internal" ? (
                  <>
                    {options.map((item) => {
                      return (
                        <>
                          {item.label === "Internal" && (
                            <Select
                              isMulti
                              name={item.name}
                              options={item.option.map((food) => ({
                                value: food.label,
                                label: food.label,
                                name: item.name,
                              }))}
                              onChange={(name) => {
                                handleInternal(name);
                              }}
                              placeholder={item?.placeholder}
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {selectedDrinks.map((item) => {
            return (
              <>
                {item.label === "External" ? (
                  <>
                    {options.map((item) => {
                      return (
                        <>
                          {item.label === "External" &&
                            item.option.map((item) => {
                              return (
                                <>
                                  <label className="block text-sm font-semibold text-gray-500">
                                    {trimAndJoinString(item.name)}
                                  </label>
                                  <input
                                    onChange={EventChange}
                                    // value={othervalue}
                                    id={item.id}
                                    name={item.name}
                                    type={item.type}
                                    required={isRequired}
                                    className={"w-full h-9 focus:outline-none"}
                                    placeholder={item.placeholder}
                                  />
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {selectedDrinks.map((item) => {
            return (
              <>
                {item.label === "Student" ? (
                  <>
                    {options.map((item) => {
                      return (
                        <>
                          {item.label === "Student" && (
                            <>
                              {" "}
                              <label className="block text-sm font-semibold text-gray-500 pt-4 pb-2">
                                {item.name}
                              </label>{" "}
                              <Select
                                isMulti
                                name={item.name}
                                options={item.option.map((food) => ({
                                  value: food.label,
                                  label: trimAndJoinString(food.label),
                                  id: food.label,
                                  name: item.name,
                                  subtext: food.subtext,
                                }))}
                                onChange={(name) => {
                                  handleStudentCourceNum(name);
                                }}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                    {student.map((item) => {
                      return (
                        <>
                          <>
                            <label className="block text-sm font-semibold text-gray-500 pt-4 pb-2">
                              {removeSpacesAndJoin(item.label)}
                            </label>{" "}
                            <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                              {" "}
                              {item?.subtext}
                            </p>
                            <input
                              onChange={EventChange}
                              // value={othervalue}
                              id={item.id}
                              name={item.name}
                              type="number"
                              required={isRequired}
                              className={"w-full h-9 focus:outline-none"}
                              placeholder="Enter no of Student"
                            />
                            <div className="error">
                              {formErrorsobj && (
                                <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                                  {formErrorsobj[item.id]}
                                </p>
                              )}
                            </div>
                          </>
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}{" "}
          {selectedDrinks.map((item) => {
            return (
              <>
                {item.label === "Member" ? (
                  <>
                    {options.map((item) => {
                      return (
                        <>
                          {item.label === "Member" && (
                            <>
                              <label className="block text-sm font-semibold text-gray-500 pt-4 pb-2">
                                {item.name}
                              </label>{" "}
                              <Select
                                isMulti
                                name={item.name}
                                options={item?.option.map((food) => ({
                                  value: food.label,
                                  label: food.label,
                                  id: food.id,
                                  name: item.name,
                                  formErrors: formErrors,
                                  subtext: food.subtext,
                                }))}
                                onChange={(name) => {
                                  handleMemberNum(name);
                                }}
                              />
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {internalName?.map((item) => {
            return (
              <>
                <>
                  <label className="block text-sm font-semibold text-gray-500 pt-4 pb-2">
                    {item.label}
                  </label>{" "}
                  <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                    {" "}
                    {item.subtext}
                  </p>
                  {item.label === "Internal Members" ? (
                    <input
                      onChange={EventChange}
                      // value={othervalue}
                      id={item.id}
                      name={item.name}
                      type="number"
                      required={isRequired}
                      className={"w-full h-9 focus:outline-none"}
                      placeholder="Number of Internal Members"
                    />
                  ) : (
                    <>
                      {item.label === "External Guests"
                        ? options?.map((item) => {
                            return (
                              <>
                                {item?.option[1].label === "External Guests" ? (
                                  <>
                                    {" "}
                                    <div className="">
                                      {" "}
                                      {item?.option[1]?.option?.map((item) => {
                                        return (
                                          <>
                                            <label className="block text-sm font-semibold text-gray-500 ">
                                              {item.label}
                                            
                                            </label>{" "}
                                            {item?.label !==
                                            "Gift Requirement for Guest" ? (
                                              <input
                                                onChange={EventChange}
                                                // value={othervalue}
                                                id={item.id}
                                                name={name}
                                                type={item.type}
                                                className={
                                                  "w-full h-9 focus:outline-none"
                                                }
                                                placeholder={item.placeholder}
                                              />
                                            ) : (
                                              <Select
                                                isMulti={false}
                                                name={name}
                                                options={item?.option.map(
                                                  (food) => ({
                                                    value: food.label,
                                                    label: trimAndJoinString(
                                                      food.label
                                                    ),
                                                    id: food.id,
                                                    name: name,
                                                    otherid: food?.id,
                                                  })
                                                )}
                                                onChange={(name) => {
                                                  handleGuestGift(name);
                                                }}
                                              />
                                            )}
                                          </>
                                        );
                                      })}
                                    </div>{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })
                        : ""}
                    </>
                  )}
                  {guestGift?.value === "Others" ? (
                    <>
                      {" "}
                      <label className="block text-sm font-semibold text-gray-500">
                        {guestGift?.value}
                      </label>
                      <input
                        onChange={EventChange}
                        // value={othervalue}
                        id={guestGift?.otherid}
                        name={name}
                        className={"w-full h-9 focus:outline-none"}
                        placeholder={"Enter other gifting details"}
                      />{" "}
                    </>
                  ) : (
                    ""
                  )}
                  <div className="error">
                    {item.id === "Internal_Guests" && (
                      <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                        {formErrorsobj?.Internal_Guests}
                      </p>
                    )}
                    {item.id === "External_Guests" &&
                    formErrorsobj?.External_Guests ? (
                      <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                        {formErrorsobj[item.id]}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              </>
            );
          })}
          {labelText === "Is there any requirement for a student moderator?" ? (
            <>
              {" "}
              <label className="block text-sm font-semibold text-gray-500 ">
                {labelText}
              </label>{" "}
              <Select
                isMulti={false}
                name={name}
                options={options.map((food) => ({
                  value: food.label,
                  label: trimAndJoinString(food.label),
                  id: id,

                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handleStudentModerator(name);
                }}
              />
            </>
          ) : (
            ""
          )}
          {labelText === "Sections open for" ? (
            <>
              {" "}
              <div className="">
                {" "}
                <label className="block text-sm font-semibold text-gray-500 ">
               If this event is for any particular sections, please specify.
                </label>{" "}
                <Select
                  isMulti
                  name={name}
                  options={options.map((food) => ({
                    value: food.label,
                    label: trimAndJoinString(food.label),
                    id: id,
                    name: name,
                    // subtext: food.subtext,
                  }))}
                  onChange={(name) => {
                    handleSection(name);
                  }}
                />
              </div>{" "}
            </>
          ) : (
            ""
          )}
          {labelText == "Please provide the image link for the silver frame (if any)." ? (
            <>
              {" "}
              <label className="block text-sm font-semibold text-gray-500">
                {labelText}
              </label>{" "}
              <input
                onChange={EventChange}
                // value={othervalue}
                id={id}
                name={name}
                type={type}
                className={"w-full h-9 focus:outline-none"}
                placeholder={"Enter the image link."}
              />{" "}
            </>
          ) : (
            ""
          )}
          {labelText ==
          "Please specify if you need Auditorium (Once confirmed, it can not be cancelled)" ? (
            <>
              
              <label className="block text-sm font-semibold text-gray-500">
                {labelText}
              </label>
              <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                {" "}
                {subtext}
              </p>
              <input
                onChange={EventChange}
                // value={othervalue}
                id={id}
                name={name}
                type={type}
                className={"w-full h-9 focus:outline-none"}
                placeholder={"Enter auditorium details"}
              />{" "}
               {formErrorsobj && (
                <div className="error">
                  <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                    {formErrorsobj[id]}
                  </p>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          {StudentModerator && (
            <div className="mt-4">
              {" "}
              <textarea
                onChange={EventChange}
                // Set the number of rows you want to display in the textarea
                cols={70}
                // value={othervalue}
                id={StudentModerator?.id}
                name={name}
                type={type}
                className={"w-full h-9 focus:outline-none"}
                placeholder="Enter the details"
              />
            </div>
          )}
          {labelText === "Total Number of Attendees" ? (
            <>
              <label className="block text-sm font-semibold text-gray-500">
                {name}
              </label>
              <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                {" "}
                {subtext}
              </p>
              <input
                onChange={EventChange}
                // value={othervalue}
                id={id}
                name={name}
                type={type}
                className={"w-full h-9 focus:outline-none"}
                placeholder={Placeholder}
              />
              {formErrors && (
                <div className="error">
                  <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                    {formErrors}
                  </p>
                </div>
              )}
            </>
          ) : (
            ""
          )}
          {labelText === "Equipment/ Technology" ? (
            <div className="">
              {" "}
              <label className="block text-sm font-semibold text-gray-500 ">
                Please specify the equipment and tech support you need.
              </label>{" "}
              <Select
                isMulti
                name={name}
                options={options.map((food) => ({
                  value: food.label,
                  label: trimAndJoinString(food.label),
                  id: id,
                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  SpecificRequirement(name);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {labelText ===
          "Please confirm the catering quality that you would need. This should be in line with your guest tier!" ? (
            <div className="">
              {" "}
              <label className="block text-sm font-semibold text-gray-500 ">
                Please confirm the catering quality that you would need. This
                should be in line with your guest tier!
              </label>{" "}
              <Select
                isMulti={false}
                name={name}
                options={options.map((food) => ({
                  value: food.label,
                  label: food.label,
                  id: id,
                  // name: name,
                  // subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handlefoodQuality(name);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {labelText ===
          "Please select all meals that you would need to be arranged for your guest" ? (
            <div className="">
              {" "}
              <label className="block text-sm font-semibold text-gray-500 ">
                Please select all meals that you would need to be arranged for
                your guest
              </label>{" "}
              <Select
                isMulti
                name={name}
                options={options.map((food) => ({
                  value: food.label,
                  label: food.label,
                  id: id,
                  // name: name,
                  // subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handlemeals(name);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {specificReq?.map((item) => {
            return (
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500 ">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item.subtext}
                </p>
                <textarea
                  id={item.id}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                <div className="error">
                  {formErrorsobj && (
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item.value]}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {labelText === "Seating" ? (
          <div className="">
            {" "}
            <label className="block text-sm font-semibold text-gray-500 ">
              Please specify the seating arrangement you need.
            </label>{" "}
            <Select
              isMulti
              name={name}
              options={options.map((food) => ({
                value: food.label,
                label: trimAndJoinString(food.label),
                name: name,
                subtext: food?.subtext,
                id: food?.id,
              }))}
              onChange={(name) => {
                handleSeating(name);
              }}
            />
          </div>
        ) : (
          <></>
        )}
        {seating?.map((item) => {
          return (
            <>
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500 ">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item?.subtext}
                </p>
                <textarea
                  id={item.id}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                <div className="error">
                  {formErrorsobj && (
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item.id]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

        {labelText === "Special Requirement" ? (
          <div className="pt-2">
            <>
              <label className="block text-sm font-semibold text-gray-500 ">
                Please specify if you have any special requirements.
              </label>

              <Select
                isMulti
                name={name}
                options={options.map((food) => ({
                  value: food.label,
                  label: trimAndJoinString(food.label),
                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handleSpecialAccommodation(name);
                }}
              />
            </>
            {SpecialAccommodation?.map((item) => {
              return (
                <>
                  {" "}
                  {item.label != "Photography and Videography" ? (
                    <>
                      {" "}
                      <div className="pt-2">
                        <label className="block text-sm font-semibold text-gray-500">
                          {item.label}
                        </label>
                        <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                          {" "}
                          {item?.subtext}
                        </p>
                        <textarea
                          id={item.value}
                          onChange={EventChange}
                          placeholder="Enter the Description"
                          className="w-full focus:outline-none"
                        />
                        {formErrorsobj && (
                          <div className="error">
                            <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                              {formErrorsobj[item?.value]}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      {options?.map((item) => {
                        return (
                          <div className="pt-1">
                            {item.label === "Photography and Videography" ? (
                              <>
                                {" "}
                                <>
                                  <label className="block text-sm font-semibold text-gray-500">
                                    {item.label}
                                  </label>

                                  <Select
                                    isMulti
                                    name={name}
                                    options={item.option.map((food) => ({
                                      value: food.label,
                                      label: food.label,
                                      name: item.label,
                                    }))}
                                    onChange={(name) => {
                                      handlePhotoVideo(name);
                                    }}
                                  />
                                </>{" "}
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              );
            })}
            {photoVideo?.map((item) => {
              return (
                <>
                  <div className="pt-2">
                    <label className="block text-sm font-semibold text-gray-500">
                      {item.label}
                    </label>
                    <textarea
                      id={item.value}
                      onChange={EventChange}
                      placeholder="Enter the Description"
                      className="w-full focus:outline-none"
                    />
                    {formErrors && (
                      <div className="error">
                        <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                          {formErrors}
                        </p>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        ) : (
          <></>
        )}
        {labelText === "Special Guests or Performers" ? (
          <div className="pt-2">
            <label className="block text-sm font-semibold text-gray-500">
              {name}
            </label>
            <textarea
              id={id}
              onChange={EventChange}
              placeholder="Enter the Description"
              className="w-full focus:outline-none"
            />
            {formErrorsobj && (
              <div className="error">
                <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                  {formErrorsobj[id]}
                </p>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        {labelText === "Standees" ? (
          <>
            <>
              <label className="block text-sm font-semibold text-gray-500">
                Do you need Standees?
              </label>

              <Select
                isMulti
                name={name}
                options={options?.map((food) => ({
                  value: food.label,
                  label: food.label,
                  name: id,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handleStandees(name);
                }}
              />
            </>{" "}
          </>
        ) : (
          ""
        )}
        {standeesBol?.map((item) => {
          return (
            <>
              <div>
                {options?.map((item) => {
                  return (
                    <>
                      <>
                        {item?.option?.map((item) => {
                          return (
                            <>
                              {formErrorsobj && (
                                <div className="error">
                                  <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                                    {formErrorsobj[item.name]}
                                  </p>
                                </div>
                              )}

                              <div className="pt-2">
                                {" "}
                                <label className="block text-sm font-semibold text-gray-500">
                                  {" "}
                                  {item.name}
                                </label>
                                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                                  {" "}
                                  {item.subtext}
                                </p>
                                <input
                                  onChange={EventChange}
                                  // value={othervalue}
                                  id={item?.id}
                                  name={item?.name}
                                  type={item?.type}
                                  className={"w-full h-9 focus:outline-none"}
                                  placeholder={item?.Placeholder}
                                />
                                {formErrors && (
                                  <div className="error">
                                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                                      {formErrors}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}{" "}
                      </>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
        {labelText == "Logistics" ? (
          <>
            <>
              <label className="block text-sm font-semibold text-gray-500">
                Please specify the logistics requirement?
              </label>

              <Select
                isMulti
                name={name}
                options={options?.map((food) => ({
                  value: food.label,
                  label: trimAndJoinString(food.label),
                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handleLogistics(name);
                }}
              />
            </>{" "}
          </>
        ) : (
          ""
        )}
        {logistics?.map((item) => {
          return (
            <>
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500 ">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item.subtext}
                </p>
                <textarea
                  id={item.value}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                {formErrorsobj && (
                  <div className="error">
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item.value]}
                    </p>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {labelText == "Food Category" ? (
          <>
            <>
              <label className="block text-sm font-semibold text-gray-500 ">
                {trimAndJoinString(name)}
              </label>

              <Select
                isMulti
                name={name}
                options={options?.map((food) => ({
                  value: food.label,
                  label: food.label,
                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  handleFood(name);
                }}
              />
            </>
          </>
        ) : (
          ""
        )}
        {food?.map((item) => {
          return (
            <>
              {item.label === "In-house Catering" && (
                <>
                  <>
                    <>
                      <label className="block text-sm font-semibold text-gray-500">
                        {item.label}
                      </label>

                      <Select
                        isMulti
                        name={name}
                        options={options[0]?.option?.map((food) => ({
                          value: food.label,
                          label: trimAndJoinString(food.label),
                          name: options[0].name,
                          subtext: food.subtext,
                        }))}
                        onChange={(name) => {
                          Inhouse(name);
                        }}
                      />
                    </>
                  </>
                </>
              )}
            </>
          );
        })}

        {food?.map((item) => {
          return (
            <>
              {item.label === "Vendor" && (
                <>
                  <>
                    <>
                      <label className="block text-sm font-semibold text-gray-500 ">
                        {item.label}
                      </label>
                      <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                        {" "}
                        {item.subtext}
                      </p>
                      <textarea
                        id={item.value}
                        onChange={EventChange}
                        placeholder="Enter the Description"
                        className="w-full focus:outline-none"
                      />
                      {formErrorsobj && (
                        <div className="error">
                          <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                            {formErrorsobj[item?.label]}
                          </p>
                        </div>
                      )}
                    </>
                  </>
                </>
              )}
            </>
          );
        })}

        {labelText == "Food Preferences & Cutlery" ? (
          <>
            <>
              <label className="block text-sm font-semibold text-gray-500">
                {name}
              </label>

              <Select
                isMulti
                name={name}
                options={options?.map((food) => ({
                  value: food.label,
                  label: trimAndJoinString(food.label),
                  name: name,
                  subtext: food.subtext,
                }))}
                onChange={(name) => {
                  FoodPreferencesCutlery(name);
                }}
              />
            </>
          </>
        ) : (
          ""
        )}
        {FoodPreferences?.map((item) => {
          return (
            <>
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item.subtext}
                </p>
                <textarea
                  id={item?.value}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                {formErrorsobj && (
                  <div className="error">
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item?.value]}
                    </p>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {labelText === "Tier" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              Gifting Tier{" "}
              <a
                className="underline underline-offset-1"
                href="https://bit.ly/mugiftinginfo"
              >
                {" "}
                (Please refer to the image)
              </a>
            </label>
            <Select
              isMulti={false}
              name={name}
              options={options?.map((food) => ({
                value: food.label,
                label: food.label,
                name: name,
              }))}
              onChange={(name) => {
                TierFunc(name);
              }}
            />{" "}
          </>
        ) : (
          ""
        )}
        {labelText === "Budget" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              Gifting Budget in INR{" "}
              <a
                className="underline underline-offset-1"
                href="https://bit.ly/mugiftinginfo"
              >
                {" "}
                (Please refer to the image)
              </a>
            </label>
            <Select
              isMulti={false}
              name={name}
              options={options?.map((food) => ({
                value: food.label,
                label: food.label,
                name: name,
              }))}
              onChange={(name) => {
                TierFunc(name);
              }}
            />{" "}
          </>
        ) : (
          ""
        )}

        {labelText === "Approval Required" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              Approval required for gifting{" "}
              <a
                className="underline underline-offset-1"
                href="https://bit.ly/mugiftinginfo"
              >
                {" "}
                (Please refer to the image)
              </a>
            </label>
            <Select
              isMulti={false}
              name={name}
              options={options?.map((food) => ({
                value: food.label,
                label: food.label,
                name: name,
              }))}
              onChange={(name) => {
                TierFunc(name);
              }}
            />{" "}
          </>
        ) : (
          ""
        )}
        {labelText === "Inclusions" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              Gifting Inclusions{" "}
              <a
                className="underline underline-offset-1"
                href="https://bit.ly/mugiftinginfo"
              >
                {" "}
                (Please refer to the image)
              </a>
            </label>
            <Select
              isMulti={false}
              name={name}
              options={options?.map((food) => ({
                value: food.label,
                label: food.label,
                name: name,
              }))}
              onChange={(name) => {
                TierFunc(name);
              }}
            />{" "}
          </>
        ) : (
          ""
        )}
        {inhouse?.map((item) => {
          return (
            <>
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item.subtext}
                </p>
                <textarea
                  id={item?.value}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                {formErrorsobj && (
                  <div className="error">
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item?.value]}
                    </p>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {labelText === "Special Gifting" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              {name}
            </label>
            <Select
              isMulti
              name={name}
              options={options?.map((food) => ({
                value: food.label,
                label: trimAndJoinString(food.label),
                name: id,
                subtext: food.subtext,
              }))}
              onChange={(name) => {
                handleGifting(name);
              }}
            />{" "}
          </>
        ) : (
          ""
        )}
        {gifting?.map((item) => {
          return (
            <>
              <div className="pt-2">
                <label className="block text-sm font-semibold text-gray-500">
                  {item.label}
                </label>
                <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
                  {" "}
                  {item.subtext}
                </p>
                <textarea
                  id={item?.value}
                  onChange={EventChange}
                  placeholder="Enter the Description"
                  className="w-full focus:outline-none"
                />
                {formErrorsobj && (
                  <div className="error">
                    <p className="text-red-500 font-normal text-sm font-sans font-normal ">
                      {formErrorsobj[item?.value]}
                    </p>
                  </div>
                )}
              </div>
            </>
          );
        })}
        {labelText ===
        "If you have any marketing requirements, please mention the same." ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              If you have any marketing requirements, please mention the same.
            </label>
            <p className="text-sm font-sans font-normal  text-gray-700 leading-[21.98px] align-baseline tracking-[0]">
              {" "}
{<>For example, <br/>
Pre event - There will be an announcement post 24 hours ahead of the event tagging the guest and the company. <br/> 
During Event - This event will be fully recorded by our internal team. Class photo to be clicked at the end of the event <br/>
After event - Please share the photos with us. Also a thank you note on LinkedIN with some handpicked photos</>} 
            </p>
          
            <textarea
              id={id}
              onChange={EventChange}
              placeholder="Enter the Description"
              className="w-full focus:outline-none"
            />{" "}
          </>
        ) : (
          ""
        )}


{labelText === "Is there any additional information or specific requests you would like to share regarding the event?" ? (
          <>
            {" "}
            <label className="block text-sm font-semibold text-gray-500">
              {name}
            </label>
            <textarea
              id={id}
              onChange={EventChange}
              placeholder="Enter the Description"
              className="w-full focus:outline-none"
            />{" "}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
